import React from "react"
import { Link } from "gatsby"

//import styled from 'styled-components';
import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import SEO from "../components/seo"
import { useSpring, animated , config } from 'react-spring';


const ContactPage = () => {

  const fadeIn = useSpring({
    from: {
        color: '#000',
        opacity: 0         
    },
    to: {
        color: '#01FF70',
        opacity: 1
    },
    delay: 500,
    config: config.gentle
  });

  
  return(
  <Layout>
    <SEO title="Contact" keywords={[`developer`, `application`, `react`, `designer`, `Victoria`, `British Columbia`, `contact`]} />

    <animated.div style={fadeIn} className="flex items-center h-screen w-full">
    <div className="w-full bg-black rounded shadow-lg p-8 m-4 md:max-w-sm md:mx-auto">

    <h1 className="text-5xl leading-tight tracking-tighter py-6" >Get In Touch</h1>

    
        <ContactForm />

    
      <Link to="/" className="inline-flex items-center w-full">
        <svg className="stroke-current w-4 h-4 mr-2"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
        <span className="py-4">home</span >
      </Link>
    
    </div>

    </animated.div>

  </Layout>
  )
}

export default ContactPage
